var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"1000","persistent":""},model:{value:(_vm.mostra),callback:function ($$v) {_vm.mostra=$$v},expression:"mostra"}},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.concluir.apply(null, arguments)}}},[_c('fieldset',[_c('v-card',[_c('v-col',{attrs:{"cols":"12"}},[_c('SeletorDeLojasDoUsuario',{ref:"seletorDeLojasRef",staticStyle:{"margin-left":"15px"},attrs:{"prepend-icon":"mdi-store","label":"Loja","clearable":"","disabled":_vm.itensDaPreVenda.length > 0 || _vm.carregando,"usuario":_vm.usuario},model:{value:(_vm.lojaSelecionada),callback:function ($$v) {_vm.lojaSelecionada=$$v},expression:"lojaSelecionada"}}),_c('h4',{staticClass:"grey--text text-subtitle-2 ml-2"},[_vm._v("Identificador: "+_vm._s(_vm.preVenda.identificador))])],1),(_vm.lojaSelecionada)?_c('v-card-actions',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{staticClass:"flex-shrink-0 d-flex",staticStyle:{"text-transform":"initial","position":"relative"},attrs:{"block":"","dark":"","color":"primary","height":"35","center":"","loading":_vm.carregandoItemNaPreVenda},on:{"click":function($event){_vm.mostrarDialogoSelecaoProduto = true}}},[_vm._v(" Selecionar Produtos ")])],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{staticClass:"flex-shrink-0 d-flex",staticStyle:{"text-transform":"initial","position":"relative"},attrs:{"block":"","dark":"","color":"primary","height":"35","center":""},on:{"click":function () {
								_vm.mostrarDialogoLeituraDeCodigoBarras = true
								_vm.showCamera = true
							}}},[_c('v-icon',[_vm._v(" mdi-barcode-scan ")]),_c('div',{staticClass:"ml-2"},[_vm._v("Bipar produtos")])],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{staticClass:"flex-shrink-0 d-flex",staticStyle:{"text-transform":"initial","position":"relative"},attrs:{"block":"","dark":"","color":"primary","height":"35","center":""},on:{"click":function () { return _vm.dialogoCsv.mostrar(_vm.preVenda.id); }}},[_c('v-icon',[_vm._v(" mdi-file-upload ")]),_c('div',{staticClass:"ml-1"},[_vm._v("Importar")])],1)],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mt-5"},[[_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" Itens da pré-venda ")]),_c('v-expansion-panel-content',[_c('v-row',[_c('v-col',{staticClass:"ml-7",attrs:{"cols":"11","sm":"10"}},[_c('v-text-field',{attrs:{"label":"Pesquisa...","single-line":"","hide-details":""},model:{value:(_vm.pesquisa),callback:function ($$v) {_vm.pesquisa=$$v},expression:"pesquisa"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{staticClass:"mt-6",attrs:{"color":"primary","right":"","filled":"","small":"","max-width":"155"},on:{"click":_vm.buscarItensDaPreVenda}},[_vm._v(" buscar ")])],1)],1),_c('v-card-text',[_c('v-data-table',_vm._g(_vm._b({attrs:{"items":_vm.itensDaPreVenda,"headers":_vm.headers,"loading":_vm.carregando,"server-items-length":_vm.totalRegistros,"options":_vm.paginacao,"manterPaginas":"","must-sort":""},on:{"update:options":function($event){_vm.paginacao=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
							var item = ref.item;
return [(_vm.itensDaPreVenda.length > 0)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var tooltip = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","disabled":_vm.cancelando.includes(item.id)},on:{"click":function($event){return _vm.removerItemDaPreVenda(item.id)}}},Object.assign({}, tooltip)),[_vm._v(" "+_vm._s(_vm.cancelando.includes(item.id) ? 'mdi-loading mdi-spin' : 'mdi-delete')+" ")])]}}],null,true)},[_vm._v(" Excluir item ")]):_vm._e()]}}],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners))],1)],1)],1)],1)]],2)],1),(!_vm.editandoPreVenda)?_c('v-card-actions',{staticClass:"justify-space-between"},[_c('div',{staticClass:"v-btn v-btn--flat v-btn--text theme--light v-size--default grey--text",style:({ 'pointer-events': _vm.salvandoPreVenda ? 'none' : 'auto' }),attrs:{"role":"button","tabindex":"0"},on:{"click":_vm.cancelar}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"disabled":!_vm.lojaSelecionada,"type":"submit","color":"success","loading":_vm.salvandoPreVenda}},[_vm._v(" Confirmar ")])],1):_c('v-card-actions',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"justify-end",attrs:{"disabled":!_vm.lojaSelecionada,"type":"submit","color":"success"}},[_vm._v(" Confirmar ")])],1)],1)],1)]),_c('SeletorDeProdutos',{attrs:{"mostrar":_vm.mostrarDialogoSelecaoProduto,"lojaId":_vm.preVenda.loja ? _vm.preVenda.loja.id : null},on:{"cancelar":function (mostrar) { return _vm.mostrarDialogoSelecaoProduto = mostrar; },"inserirProduto":_vm.atualizarListaDeProdutos}}),_c('v-dialog',{attrs:{"persistent":"","fullscreen":"","loading":_vm.buscandoProduto},model:{value:(_vm.mostrarDialogoLeituraDeCodigoBarras),callback:function ($$v) {_vm.mostrarDialogoLeituraDeCodigoBarras=$$v},expression:"mostrarDialogoLeituraDeCodigoBarras"}},[_c('v-card',{staticClass:"overflow-hidden ma-0 pa-0",attrs:{"height":"auto"}},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v(" Leitor de Código de Barras ")]),_c('v-btn',{staticClass:"ml-auto",attrs:{"dark":"","icon":""},on:{"click":function () {
					_vm.mostrarDialogoLeituraDeCodigoBarras = false
					_vm.showCamera = false
				}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[(_vm.showCamera)?_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('h3',[_vm._v("Aproxime código de barras da câmera")])])],1):_vm._e(),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"align-self-center ma-0 pa-0",attrs:{"cols":"12"}},[(_vm.showCamera)?_c('div',{staticClass:"ma-0 pa-0"},[_c('v-quagga',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCamera),expression:"showCamera"}],attrs:{"onDetected":_vm.logIt,"readerSize":_vm.readerSize,"readerTypes":['ean_reader']}})],1):_c('div',{staticClass:"align-self-center ma-0 pa-0",attrs:{"align":"center"}},[(!_vm.showCamera)?_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('h3',[_vm._v("Aproxime código de barras da câmera")])])],1):_vm._e(),_c('v-progress-circular',{attrs:{"align":"center","indeterminate":""}})],1)])],1)],1)],1)],1),_c('DialogoDeCsvItemDaPreVenda',{ref:"dialogoCsv",on:{"concluiuImportacao":_vm.buscarItensDaPreVenda}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }