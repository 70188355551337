var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fit-content"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"mt-2"},[_c('SeletorDeLojasDoUsuario',{staticStyle:{"margin-left":"20px"},attrs:{"prepend-icon":"mdi-store","label":"Loja","usuario":_vm.usuario,"clearable":"","return-object":""},model:{value:(_vm.loja),callback:function ($$v) {_vm.loja=$$v},expression:"loja"}})],1),_c('v-col',{staticStyle:{"margin-top":"-0.5%"}},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Busca por identificador","single-line":"","hide-details":""},model:{value:(_vm.busca),callback:function ($$v) {_vm.busca=$$v},expression:"busca"}})],1)],1),_c('v-col',{staticStyle:{"margin-top":"0.5%"},attrs:{"cols":"2"}},[_c('v-select',{attrs:{"label":"Selecione a situação","items":['Aberto', 'Concluído'],"multiple":""},model:{value:(_vm.situacoes),callback:function ($$v) {_vm.situacoes=$$v},expression:"situacoes"}})],1),_c('v-col',{staticStyle:{"margin-top":"0.5%"},attrs:{"cols":"7","sm":""}},[_c('RangeDatePicker',{attrs:{"label":"Data","inicial":{
						label: 'Data Inicial',
						clearable: true,
					},"final":{
						label: 'Data Final',
						clearable: true,
					}},model:{value:(_vm.datas),callback:function ($$v) {_vm.datas=$$v},expression:"datas"}})],1),_c('v-col',{staticClass:"d-flex align-center text-center",attrs:{"cols":"12","sm":"auto"}},[_c('v-btn',{attrs:{"color":"primary","width":"110","type":"submit"},on:{"click":function () { return _vm.buscar(); }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-magnify ")]),_vm._v(" Aplicar ")],1)],1)],1),_c('v-data-table',_vm._g(_vm._b({attrs:{"items":_vm.preVendasFormatados,"headers":_vm.headers,"loading":_vm.carregando,"server-items-length":_vm.totalRegistros,"options":_vm.paginacao,"must-sort":""},on:{"update:options":function($event){_vm.paginacao=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
					var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
					var on = ref.on;
return [(item.situacao === 'Aberto')?_c('v-icon',_vm._g({attrs:{"small":"","disabled":_vm.excluindo.includes(item.id)},on:{"click":function () { return _vm.dialogoDeCadastroDaPreVenda.mostrar(item, true); }}},on),[_vm._v(" "+_vm._s('mdi-pencil')+" ")]):_vm._e()]}}],null,true)},[_vm._v(" Editar pré-venda ")]),_c('Confirmacao',{attrs:{"width":"300","titulo":"Excluir pré-venda?"},on:{"confirmar":function($event){return _vm.excluir(item.id)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
					var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
					var tooltip = ref.on;
return [(item.situacao === 'Aberto')?_c('v-icon',_vm._g({attrs:{"small":"","disabled":_vm.excluindo.includes(item.id)}},Object.assign({}, dialog, tooltip)),[_vm._v(" "+_vm._s(_vm.excluindo.includes(item.id) ? 'mdi-loading mdi-spin' : 'mdi-delete')+" ")]):_vm._e()]}}],null,true)},[_vm._v(" Excluir pré-venda ")])]}}],null,true)})]}},{key:"item.situacao",fn:function(ref){
					var item = ref.item;
return [_c('span',{class:_vm.statusColor(item.situacao)},[_vm._v(" "+_vm._s(item.situacao)+" ")])]}},{key:"body.append",fn:function(ref){
					var headers = ref.headers;
return [_c('tr',[_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[_c('v-btn',{ref:"btnAdicionarItem",staticClass:"justify-start",attrs:{"text":"","color":"success","block":"","data-cy":"criar"},on:{"click":function($event){return _vm.dialogoDeCadastroDaPreVenda.mostrar()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Criar pré-venda ")],1)],1)])]}}],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners)),_c('DialogoDeCadastroDaPreVenda',{ref:"dialogoDeCadastroDaPreVenda",on:{"buscarPreVendas":function($event){return _vm.buscar()}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }