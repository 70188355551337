var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"480","persistent":""},model:{value:(_vm.mostra),callback:function ($$v) {_vm.mostra=$$v},expression:"mostra"}},[_c('v-card',[_c('v-card-text',{staticClass:"mt-5"},[_c('v-tabs',{attrs:{"dark":"","background-color":"primary"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(tab){return _c('v-tab',{key:tab.icon},[_c('v-icon',[_vm._v(" "+_vm._s(tab.icon)+" ")]),_vm._v(" "+_vm._s(tab.action)+" ")],1)}),1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{attrs:{"eager":""}},[_c('v-form',{ref:"form 0"},[_c('v-container',[_c('v-row',[_c('VueCsvImport',{ref:"csvComponent",attrs:{"map-fields":_vm.camposCsvItemImportar,"auto-match-fields":true,"tableSelectClass":("elevation-" + (_vm.hover ? 24 : 6) + " rounded-lg pa-1 mb-2 ml-1  removeBorda"),"tableClass":"mt-2","name":"csv"},scopedSlots:_vm._u([{key:"hasHeaders",fn:function(ref){
var headers = ref.headers;
var toggle = ref.toggle;
return [_c('div',[_c('v-row',{staticClass:"ml-0"},[_c('v-checkbox',{staticClass:"mr-1",attrs:{"id":"hasHeaders","value":headers,"label":"Arquivo com cabeçalho"},on:{"change":toggle}})],1),_c('btn',{on:{"click":_vm.arquivo}},[_vm._v(" Selecionar Arquivo ")]),_c('v-divider',{staticClass:"mb-2"})],1)]}},{key:"next",fn:function(ref){
var load = ref.load;
return [_c('div',[_c('v-btn',{on:{"click":function($event){$event.preventDefault();return _vm.clickCsv.apply(null, arguments)}}},[_vm._v(" Escolher arquivo ")]),_c('div',[_vm._v(" "+_vm._s(_vm.arquivoSelecionado)+" ")])],1),_c('div',[_c('v-divider',{staticClass:"my-4"}),_c('v-btn',{on:{"click":function($event){$event.preventDefault();return load.apply(null, arguments)}}},[_vm._v(" Definir Colunas ")])],1)]}}]),model:{value:(_vm.csv),callback:function ($$v) {_vm.csv=$$v},expression:"csv"}},[_c('template',{slot:"thead"},[_c('thead',[_c('tr',[_c('th',[_vm._v(" Campos "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-8",attrs:{"color":"info","small":""}},on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('div',{staticClass:"importacao"},[_c('span',[_vm._v(" Os nomes dos campos estão abreviados para que correspondam aos nomes das colunas do arquivo importado. ")])])])],1),_c('th',[_vm._v(" Colunas do CSV "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"info","small":""}},on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('div',{staticClass:"importacao"},[_c('span',[_vm._v(" Ao optar por não ter cabeçalho, as informações que aparecerão no campo \"colunas do CSV\", são correspondentes a primeira linha do arquivo lido ")])])])],1)])])])],2),_vm._v(" O Arquivo importado deve ser um arquivo .csv ")],1)],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"type":"button","text":""},on:{"click":_vm.cancelar}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"primary","right":"","disabled":!_vm.csv},on:{"click":_vm.concluirImportacao}},[_vm._v(" Concluir ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }