import { Page } from "@/models"
import { ItemDaPreVenda } from "@/models/pre-venda/PreVenda"
import { AxiosRequestConfig } from "axios"
import { Inject } from "inversify-props"
import type { ItemDaPreVendaServiceAdapter } from "../pre-venda"

export class ItemPreVendaUseCase {
	@Inject('ItemDaPreVendaServiceAdapter')
	private itemDaPreVendaServiceAdapter!: ItemDaPreVendaServiceAdapter

	findItensDaPreVenda = async (params: any, config: AxiosRequestConfig): Promise<Page<ItemDaPreVenda>> =>
		await this.itemDaPreVendaServiceAdapter.findItensDaPreVenda(params, config)
	
	remover = async (idItemPreVenda: string): Promise<void> =>
		await this.itemDaPreVendaServiceAdapter.remover(idItemPreVenda)

	salvarOuAtualizarItemPreVenda = async (item: ItemDaPreVenda, preVendaId: string): Promise<void> =>
		await this.itemDaPreVendaServiceAdapter.salvarOuAtualizarItemPreVenda(item, preVendaId)
}