







































































































































import SeletorDeLojasDoUsuario from '@/components/loja/SeletorDeLojasDoUsuario.vue'
import Confirmacao from '@/components/ui/Confirmacao.vue'
import RangeDatePicker from '@/components/ui/RangeDatePicker.vue'
import { Loja } from '@/models'
import { PreVenda } from '@/models/pre-venda/PreVenda'
import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { PreVendaUseCase } from '@/usecases/venda/PreVendaUseCase'
import axios, { CancelTokenSource } from 'axios'
import moment from 'moment'
import { Component, Ref, Vue, Watch } from 'vue-property-decorator'
import { DataOptions } from 'vuetify'
import DialogoDeCadastroDaPreVenda from './DialogoDeCadastroDaPreVenda.vue'

@Component({
	components: {
		RangeDatePicker,
		SeletorDeLojasDoUsuario,
		Confirmacao,
		DialogoDeCadastroDaPreVenda,
	},
})
export default class TelaDePreVendas extends Vue {
	@Ref() dialogoDeCadastroDaPreVenda!: DialogoDeCadastroDaPreVenda

	busca = ''
	carregando = false
	totalRegistros = -1
	situacoes: string[] = []
	datas = []
	excluindo: string[] = []
	preVendas: PreVenda[] = []
	cancelToken: CancelTokenSource | null = null
	loja: Loja | null = null
	
	preVendaUseCase = new PreVendaUseCase()

	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 10,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	headers = [
		{ text: 'Identificador', value: 'identificador' },
		{ text: 'Loja', value: 'loja.nomeFantasia' },
		{ text: 'Situação', value: 'situacao' },
		{ text: 'Data e hora', value: 'dataHoraCriacao' },
		{ text: 'Ações', value: 'actions', sortable: false, align: 'center' },
	]

	async created() {
		await this.buscar()
	}

	async buscar() {
		try {
			this.carregando = true
			this.cancelToken = axios.CancelToken.source()

			if (this.datasEstaoInvertidas()) {
				this.datas.reverse()
			}

			const dataHoraInicial = this.gerarDataHora(
				this.datas[0],
				'00:00',
			)
			
			const dataHoraFinal = this.gerarDataHora(
				this.datas[1],
				'23:59',
			)

			const params = {
				nome: this.busca || null,
				identificador: this.busca || null,
				page: this.busca ? -1 : this.paginacao.page - 1,
				size: this.paginacao.itemsPerPage,
				dataHoraInicial: dataHoraInicial && dataHoraFinal ? dataHoraInicial : undefined,
				dataHoraFinal: dataHoraFinal && dataHoraInicial ? dataHoraFinal : undefined,
				situacoes: this.situacoes,
				lojaId: this.loja?.id || null,
				sort: 'createdAt,desc',
			}

			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}

			const listaDePreVenda = await this.preVendaUseCase.findPreVendas(params, axiosConfig)
			this.preVendas = listaDePreVenda.content
			this.totalRegistros = listaDePreVenda.totalElements
			this.carregando = false
		} catch(e) {
			this.carregando = false
			AlertModule.setError(e)
		}
		return null
	}

	async excluir(preVendaId: string) {
		if(!preVendaId) throw new Error("Não existe prá-venda")
	
		if (this.excluindo.includes(preVendaId)) throw new Error('Pré-venda já está em excluida')
		try {
			this.excluindo.push(preVendaId)
			await this.preVendaUseCase.remover(preVendaId)
			this.excluindo = this.excluindo.filter(id => preVendaId !== id)
			this.buscar()
		} catch(e) {
			AlertModule.setError(e)
		}
	}

	gerarDataHora(data?: string | null, hora?: string | null): string | undefined {
		return moment(`${data} ${hora}`, 'YYYY-MM-DD HH:mm').toISOString(true)
	}

	statusColor(status: string) {
		if(status === 'Concluído') return 'success--text font-weight-bold'
		if(status === 'Aberto') return 'warning--text font-weight-bold'
		if(status === 'Cancelado') return 'error--text font-weight-bold'
	}

	datasEstaoInvertidas() {
		if (!this.datas[0] || !this.datas[1]) return false
		if (this.datas[1] < this.datas[0]) return true
	}

	@Watch('paginacao')
	onChangePage = this.buscar

	get preVendasFormatados() {
		return this.preVendas.map(preVenda => ({
			...preVenda,
			dataHoraCriacao: preVenda.dataHoraCriacao 
				? dateTimeToPtBrFormat(preVenda.dataHoraCriacao) 
				: '---',
		}))
	}

	get usuario() {
		return UserLoginStore.usuario
	}
}
